import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo } from "react";

import EntityEllipsisMenu from "../EntityEllipsisMenu";
import CardActionStyles from "./CardActionStyles";

import { useStyles } from "hooks/useStyles";

const EllipsisAction = (props) => {
  const {
    entity,
    entity_type,
    podcast,
    brand,
    mobile,
    showShare,
    action,
    closeOnOutsideclick,
    passedEllipsisPopoutStyles,
    page,
    disablePortal,
    analyticsProps,
    isOwner,
    toggleLike,
    edit,
    context,
  } = props;
  const { styles } = useStyles(CardActionStyles, props);

  return (
    <div
      key="ellipsis"
      className={css(
        styles.buttonIcon,
        styles.buttonContainer,
        mobile && styles.buttonContainerMobile
      )}
    >
      <EntityEllipsisMenu
        entity_type={entity_type}
        entity={entity}
        podcast={podcast}
        brand={brand}
        mobile={mobile}
        page={page}
        showShare={showShare}
        passedEllipsisButtonStyles={props?.styles}
        showEllipsisItems={action.showEllipsisItems}
        closeOnOutsideclick={closeOnOutsideclick}
        passedEllipsisPopoutStyles={passedEllipsisPopoutStyles}
        disablePortal={disablePortal}
        analyticsProps={analyticsProps}
        isOwner={isOwner}
        toggleLike={toggleLike}
        edit={edit}
        context={context}
      />
    </div>
  );
};

EllipsisAction.propTypes = {
  entity: PropTypes.instanceOf(Map),
  entity_type: PropTypes.string,
  podcast: PropTypes.instanceOf(Map),
  brand: PropTypes.instanceOf(Map),
  mobile: PropTypes.bool,
  showShare: PropTypes.bool,
  action: PropTypes.object.isRequired,
};

EllipsisAction.defaultProps = {
  entity: null,
  entity_type: null,
  podcast: null,
  brand: null,
  mobile: false,
  showShare: false,
};

export default memo(EllipsisAction);
